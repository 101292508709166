
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as clinicsJhlYuDnXQSMeta } from "/builds/mis/medflex_landing/pages/clinics.vue?macro=true";
import { default as indexPA1jtQSyzaMeta } from "/builds/mis/medflex_landing/pages/index.vue?macro=true";
import { default as insuranceyUC93ykOHQMeta } from "/builds/mis/medflex_landing/pages/insurance.vue?macro=true";
import { default as _91id_93phX7USROeEMeta } from "/builds/mis/medflex_landing/pages/integration/[id].vue?macro=true";
import { default as mis0WrQfGgWkgMeta } from "/builds/mis/medflex_landing/pages/mis.vue?macro=true";
import { default as servicesQGhlqZzZM7Meta } from "/builds/mis/medflex_landing/pages/services.vue?macro=true";
export default [
  {
    name: "clinics",
    path: "/clinics",
    component: () => import("/builds/mis/medflex_landing/pages/clinics.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/mis/medflex_landing/pages/index.vue")
  },
  {
    name: "insurance",
    path: "/insurance",
    component: () => import("/builds/mis/medflex_landing/pages/insurance.vue")
  },
  {
    name: "integration-id",
    path: "/integration/:id()",
    component: () => import("/builds/mis/medflex_landing/pages/integration/[id].vue")
  },
  {
    name: "mis",
    path: "/mis",
    component: () => import("/builds/mis/medflex_landing/pages/mis.vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/builds/mis/medflex_landing/pages/services.vue")
  }
]